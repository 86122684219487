import React, { ReactElement } from "react";
import {
    KatBox,
    KatDivider,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow,
} from "@amzn/katal-react";
import { format } from "date-fns";
import cer_apr_sept_21 from "src/assets/cer_apr_sept_21.pdf";
import cer_oct_mar_22 from "src/assets/cer_oct_mar_22.pdf";
import Environmental_Statement_Form_V_Chandanvally_Phase_1 from "src/assets/Environmental_Statement_Form_V_Chandanvally_Phase_1.pdf";
import Environmental_Statement_Form_V_Fabcity_Phase_1 from "src/assets/Environmental_Statement_Form_V_Fabcity_Phase_1.pdf";
import Corporate_Environmental_Responsibility_Progress_Report from "src/assets/Corporate_Environmental_Responsibility_Progress_Report_-_Chandanvally_and_Pharma_city.pdf";
import Environmental_Statement_Form_V_Pharmacity_Phase_1 from "src/assets/Environmental_Statement_Form_V_Pharmacity_Phase_1.pdf";

import Corporate_Environmental_Responsibility_Progress_Report_Oct22_Mar23_Chandanvally_and_Pharma_city from "src/assets/Corporate_Environmental_Responsibility_Progress_Report_Oct22_Mar23_Chandanvally_and_Pharma_city.pdf";
import Environmental_Statement_Form_V_Fabcity_Phase_1_and_2_FY_2022_23 from "src/assets/Environmental_Statement_Form_V_Fabcity_Phase_1_and_2_FY_2022_23.pdf";
import Environmental_Statement_Form_V_Chandanvally_Phase_1_and_2_FY_2022_23 from "src/assets/Environmental_Statement_Form_V_Chandanvally_Phase_1_and_2_FY_2022_23.pdf";
import Environmental_Statement_Form_V_Pharmacity_Phase_1_and_2_FY_2022_23 from "src/assets/Environmental_Statement_Form_V_Pharmacity_Phase_1_and_2_FY_2022_23.pdf";
import Corporate_Environmental_Responsibility_Progress_Report_April_23_to_September_23_Chandanvally_and_Pharma_city from "src/assets/Corporate_Environmental_Responsibility_Progress_Report_April_23_to_September_23_Chandanvally_and_Pharma_city.pdf";

import Corporate_Environmental_Responsibility_Progress_Report_Oct_23_Mar_24_Chandanvally_and_Pharma_City from "src/assets/Corporate_Environmental_Responsibility_Progress_Report_Oct_23_Mar_24_Chandanvally_and_Pharma_City.pdf";
export type Report = {
    fileName: string;
    path: string;
    dateSubmitted: string;
};

export const Logo = (): ReactElement => (
    <h1 className="text-size-special" id="Logo">
        <b>ADSIPL</b>
    </h1>
);

export const Description = (): ReactElement => (
    <p id="Description">
        Amazon Data Services India Private Limited (ADSIPL) operates data
        centers in India.
    </p>
);

// Find a better way to specify types
export const ReportsTable = ({
    reports,
}: {
    reports: Report[];
}): ReactElement => (
    <KatTable className="reports-table" id="ReportsTable">
        <KatTableHead>
            <KatTableRow>
                <KatTableCell>Report</KatTableCell>
                <KatTableCell>Date Submitted</KatTableCell>
            </KatTableRow>
        </KatTableHead>
        <KatTableBody>
            {reports.map((report, index) => (
                <KatTableRow key={index}>
                    <KatTableCell>
                        <a download href={report.path}>
                            {report.fileName}
                        </a>
                    </KatTableCell>
                    <KatTableCell>{report.dateSubmitted}</KatTableCell>
                </KatTableRow>
            ))}
        </KatTableBody>
    </KatTable>
);

export const App: React.FC = (): ReactElement => {
    const reports: Report[] = [
        {
            fileName:
                "Corporate Environmental Responsibility Progress Report (Oct '23 - Mar`24) Chandanvally and Pharma City",
            path: Corporate_Environmental_Responsibility_Progress_Report_Oct_23_Mar_24_Chandanvally_and_Pharma_City,
            dateSubmitted: "Mar 2024",
        },
        {
            fileName:
                "Corporate Environmental Responsibility Progress Report (April`23 - Sept`23) Chandanvally and Pharma City",
            path: Corporate_Environmental_Responsibility_Progress_Report_April_23_to_September_23_Chandanvally_and_Pharma_city,
            dateSubmitted: "Nov 2023",
        },
        {
            fileName:
                "Environmental Statement Form V Pharmacity Phase 1&2 FY (2022-23)",
            path: Environmental_Statement_Form_V_Pharmacity_Phase_1_and_2_FY_2022_23,
            dateSubmitted: "Sept 2023",
        },
        {
            fileName:
                "Environmental Statement Form V Chandanvally Phase 1&2 FY (2022-23)",
            path: Environmental_Statement_Form_V_Chandanvally_Phase_1_and_2_FY_2022_23,
            dateSubmitted: "Sept 2023",
        },

        {
            fileName:
                "Environmental Statement Form V Fabcity Phase 1&2 FY (2022-23)",
            path: Environmental_Statement_Form_V_Fabcity_Phase_1_and_2_FY_2022_23,
            dateSubmitted: "Sept 2023",
        },
        {
            fileName:
                "Corporate Environmental Responsibility Progress Report (Oct`22 - Mar`23) Chandanvally and Pharma city",
            path: Corporate_Environmental_Responsibility_Progress_Report_Oct22_Mar23_Chandanvally_and_Pharma_city,
            dateSubmitted: "May 2023",
        },
        {
            fileName:
                "Corporate Environmental Responsibility Progress Report - Chandanvally and Pharma city",
            path: Corporate_Environmental_Responsibility_Progress_Report,
            dateSubmitted: "December 2022",
        },
        {
            fileName:
                "Environmental Statement Form V Pharmacity Phase 1 FY 2021-22",
            path: Environmental_Statement_Form_V_Pharmacity_Phase_1,
            dateSubmitted: "September 2022",
        },
        {
            fileName:
                "Environmental Statement Form V Chandanvally Phase 1 FY 2021-22",
            path: Environmental_Statement_Form_V_Chandanvally_Phase_1,
            dateSubmitted: "September 2022",
        },
        {
            fileName:
                "Environmental Statement Form V Fabcity Phase 1 FY2021-22",
            path: Environmental_Statement_Form_V_Fabcity_Phase_1,
            dateSubmitted: "September 2022",
        },
        {
            fileName:
                "Corporate Environmental Responsibility Progress report - Chandanvelly and Pharma city",
            path: cer_oct_mar_22,
            dateSubmitted: "June 2022",
        },
        {
            fileName:
                "Corporate Environmental Responsibility Progress report - Chandanvelly and Pharma city",
            path: cer_apr_sept_21,
            dateSubmitted: "Decemeber 2021",
        },
    ];

    return (
        <div id="box">
            <KatBox variant="white-shadow" className="content-box">
                <Logo />
                <KatDivider variant="nordic" />
                <Description />
                <ReportsTable reports={reports} />
            </KatBox>
        </div>
    );
};
